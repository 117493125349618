import React from 'react';
import ReactDOM from 'react-dom';
import useDeviceType from '../utils/useDeviceType';

const Modal = ({ isOpen, onClose, photos = [], currentIndex }) => {
  const deviceType = useDeviceType(); 

  if (!isOpen || photos.length === 0) return null;

  const modalStyles = {
    closeIcon: {
      position: 'absolute',
      top: '0px',
      right: '15px',
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '40px',
      color: '#FF6347',
      cursor: 'pointer',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'start',
      width: deviceType === 'mobile' ? '350px' : 'auto',
      height: deviceType === 'mobile' ? '350px' : 'auto',
      position: 'relative',
    },
    closeButton: {
      border: 'none',
      backgroundColor: '#FF6347',
      color: 'white',
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      height: 'auto',
      width: '100%',
      marginTop: '10px',
    },
    image: {
      // width: '100%',
      // height: '400px',
      width: deviceType === 'mobile' ? '310px' : '100%',
      height: deviceType === 'mobile' ? '290px' : '400px',
      borderRadius: '5px',
      marginTop: '30px',
    },
  };

  return ReactDOM.createPortal(
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
        <span style={modalStyles.closeIcon} onClick={onClose}>×</span>
        <img
          src={photos[currentIndex]}
          alt={`Modal ${currentIndex + 1}`}
          style={modalStyles.image}
        />
      </div>
    </div>,
    document.body
  );
};

export default Modal;
