import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL, APP_URL } from '../constant';
import { RxArrowTopRight } from 'react-icons/rx';
import Layout from './Layout';
import Modal from '../components/Modal';
import useDeviceType from '../utils/useDeviceType';
import video_one from '../assets/videos/WEBSITE VIDEO_01.mp4';
import video_two from '../assets/videos/new_infra.mp4';

const ServiceDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceName = queryParams.get('category');
  const deviceType = useDeviceType();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalType, setModalType] = useState('main'); 
  const [currentSubserviceIndex, setCurrentSubserviceIndex] = useState(null); 
  const [services, setServices] = useState([]);
  const [service, setService] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAllServices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/services`, {
        params: { categoryName: serviceName },
      });
      const filteredServices = response.data?.data?.filter((service) => !service?.name?.toLowerCase().includes(serviceName?.toLowerCase()));
      const service = response.data?.data?.find((service) =>
        service?.name?.toLowerCase() === serviceName?.toLowerCase()
      );
      setService(service);
      setServices(filteredServices);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [serviceName]);

  const fetchSubServices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/sub-services`, {
        categoryName: serviceName,
      });
      setSubServices(response.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [serviceName]);

  const fetchAllProducts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/service-photos`, { category_name: serviceName });
      const photos = response.data?.data?.map(product => `${APP_URL}/storage/${product?.photo}`);
      setPhotos(photos);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [serviceName]);

  useEffect(() => {
    fetchAllServices();
    fetchSubServices();
    fetchAllProducts();
  }, [serviceName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const openModal = (index, type = 'main', subServiceIndex = null) => {
    setModalType(type); 
    setCurrentIndex(index);
    setCurrentSubserviceIndex(subServiceIndex); 
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentIndex(0);
    setCurrentSubserviceIndex(null); 
  };

  return (
    <Layout>
      <div
        style={{
          padding: deviceType === 'mobile' ? '30px' : '30px 0px',
          minHeight: '70vh'
        }}
      >
        <h1 style={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: '20px' }}>{service?.name}</h1>
        <h5 style={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: '20px' }}>{service?.description}</h5>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '25px',
        }}>
          {loading ? (
            <h2 style={{ margin: '200px' }}>loading...</h2>
          ) : (serviceName?.trim().toLowerCase() === 'laser') ? (
            <div style={{
              display: 'flex',
              flexDirection: deviceType === 'mobile' ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
              padding: '20px',
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              maxWidth: '90%',
              overflow: 'hidden',
              flexWrap: 'wrap',
            }}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: deviceType === 'mobile' ? '100%' : '48%',
                marginBottom: '20px',
              }}>
                <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: '600', marginBottom: '20px' }}>Laser Cutting</h2>
                <video
                  src={video_one}
                  type="video/mp4"
                  muted
                  controls
                  style={{
                    border: '1px solid',
                    borderColor: '#fce4de',
                    borderRadius: '12px',
                    height: 'auto',
                    maxHeight: deviceType === 'mobile' ? '' : '550px',
                    aspectRatio: deviceType === 'mobile' ? '' : '16 / 9',
                    boxSizing: 'border-box',
                    width: '100%',
                  }}
                />
              </div>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: deviceType === 'mobile' ? '100%' : '48%',
                marginBottom: '20px',
              }}>
                <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: '600', marginBottom: '20px' }}>CNC Router Cutting</h2>
                <video
                  src={video_two}
                  type="video/mp4"
                  muted
                  controls
                  style={{
                    border: '1px solid',
                    borderColor: '#fce4de',
                    borderRadius: '12px',
                    height: 'auto',
                    maxHeight: deviceType === 'mobile' ? '' : '550px',
                    aspectRatio: deviceType === 'mobile' ? '' : '16 / 9',
                    boxSizing: 'border-box',
                    width: '100%',
                  }}
                />
              </div>
            </div>
          ) : (
            <div style={{
              display: 'grid',
              gap: '20px',
              gridTemplateColumns: deviceType === 'mobile' ? '1fr' : 'repeat(3, 1fr)'
            }}>
              {photos?.map((photo, index) => (
                <div
                  key={index}
                  style={{
                    height: '300px',
                    boxSizing: 'border-box',
                    padding: deviceType === 'mobile' ? '20px' : '15px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    transition: 'transform 0.3s',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  }}
                  onClick={() => openModal(index, 'main')}
                >
                  <img
                    src={photo}
                    alt={`Service ${index + 1}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      transition: 'filter 0.3s',
                      borderRadius: '8px',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.filter = 'brightness(0.8)'}
                    onMouseOut={(e) => e.currentTarget.style.filter = 'none'}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {subServices.length > 0 && subServices.map((subService, subServiceIndex) => (
          <div key={subServiceIndex}>
            <h1 style={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: '20px' }}>{subService?.name}</h1>
            <h5 style={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: '20px' }}>{subService?.description}</h5>

            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '25px',
            }}>
              {loading ? (
                <h2 style={{ margin: '200px' }}>loading...</h2>
              ) : (
                <div style={{
                  display: 'grid',
                  gap: '20px',
                  gridTemplateColumns: deviceType === 'mobile' ? '1fr' : 'repeat(3, 1fr)'
                }}>
                  {subService.products.length > 0 && subService.products?.map((product, index) => (
                    <div
                      key={index}
                      style={{
                        height: '300px',
                        boxSizing: 'border-box',
                        padding: deviceType === 'mobile' ? '20px' : '15px',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        transition: 'transform 0.3s',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      }}
                      onClick={() => openModal(index, 'subservice', subServiceIndex)}
                    >
                      <img
                        src={`${APP_URL}/storage/${product?.photo || 'default-photo.jpg'}`}
                        alt={`Subservice ${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          transition: 'filter 0.3s',
                          borderRadius: '8px',
                        }}
                        onMouseOver={(e) => e.currentTarget.style.filter = 'brightness(0.8)'}
                        onMouseOut={(e) => e.currentTarget.style.filter = 'none'}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
        
        <p style={{ marginTop: '60px', fontSize: '20px', fontWeight: '800', textAlign: 'center' }}>Similar Services</p>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '10px',
          marginTop: '20px',
        }}>
          {services?.map((service, index) => (
            <button
              key={index}
              className="services-button"
              onClick={() => navigate(`/service-details?category=${service?.name}`)}
            >
              <span>{service?.name}</span> <RxArrowTopRight />
            </button>
          ))}
        </div>

        <Modal
          isOpen={modalOpen}
          onClose={closeModal}
          photos={modalType === 'main' ? photos : subServices[currentSubserviceIndex]?.products.map(product => `${APP_URL}/storage/${product?.photo || 'default-photo.jpg'}`)}
          currentIndex={currentIndex}
        />
      </div>
    </Layout>
  );
};

export default ServiceDetails;
  