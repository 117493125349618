import React from 'react';

const TextArea = ({ label, id, name, value, onChange, rows, placeholder }) => {
  const styles = {
    container: {
      marginBottom: '10px',
      width: '100%',
    },
    label: {
      fontWeight: 'bold',
      color: '#333',
    },
    textarea: {
      width: '100%',
      padding: '15px',
      boxSizing: 'border-box',
      border: '1px solid #ccc',
      borderRadius: '5px',
      backgroundColor: '#ffffff',
      resize: 'vertical',
      fontSize: '15px',
      lineHeight: '1.4',
      color: '#333',
      marginTop: '5px'
    },
  };

  return (
    <div style={styles.container}>
      {label && <label htmlFor={id} style={styles.label}>{label}</label>}
      <textarea
        id={id}
        name={name}
        rows={rows || 4}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={styles.textarea}
      />
    </div>
  );
};

export default TextArea;
