import infrastructure01 from '../assets/images/Infrastructure/infrastructure01.png';
import infrastructure02 from '../assets/images/Infrastructure/infrastructure02.png';
import infrastructure03 from '../assets/images/Infrastructure/infrastructure03.png';
import infrastructure04 from '../assets/images/Infrastructure/infrastructure04.jpg';
import infrastructure05 from '../assets/images/Infrastructure/infrastructure05.jpg';
import infrastructure06 from '../assets/images/Infrastructure/infrastructure06.jpg';
import infrastructure07 from '../assets/images/Infrastructure/infrastructure07.jpg';
import infrastructure08 from '../assets/images/Infrastructure/infrastructure08.jpeg';
import infrastructure09 from '../assets/images/Infrastructure/infrastructure09.jpeg';

export const infrastructureData = [
  {
    image: infrastructure01,
    name: 'HP Latex 570',
    // description: 'The HP Latex 570 is a high-performance large format printer designed for medium-to-large print service providers. It offers fast print speeds, high-quality prints, and versatility, making it suitable for a wide range of applications, including banners, posters, and vehicle wraps. The HP Latex 570 is also environmentally friendly, using water-based inks that are free of volatile organic compounds.'
  },
  {
    image: infrastructure02,
    name: 'Roland- 640',
    // description: 'The Roland DG 640 is a versatile large format printer that offers high-quality, durable prints for a variety of applications. With its advanced technology and user-friendly interface, it a popular choice among professionals in industries such as advertising, signage, and vehicle graphics. The Roland DG 640 delivers vibrant, fade-resistant prints, is compatible with a wide range of media types, and offers fast print speeds. '
  },
  {
    image: infrastructure03,
    name: 'Digita Color Konica 512i Solvent',
    // description: 'The Konica Minolta C512i Solvent is a high-performance large format printer that offers fast print speeds, high-quality prints, and versatility. It designed for professional print service providers and is suitable for a wide range of applications, including banners, posters, and vehicle wraps. The Konica Minolta C512i Solvent is also cost-effective and environmentally friendly, making it a valuable asset for businesses.'
  },
  {
    image: infrastructure04,
    name: 'XL JET lamination-machine',
    // description: 'XL JET Lamination Machines are high-performance equipment designed for large-format lamination applications. They are typically used in industries like printing, signage, and graphics production to protect and enhance printed materials. These machines can handle large-sized substrates and offer various lamination options, including hot lamination, cold lamination, and pouch lamination.' 
  },
  {
    image: infrastructure05,
    name: 'GX -640 Roland Plotter Machine',
    // description: 'The Roland GX-640 is a high-performance large format plotter designed for professional cutting applications. It offers precision, speed, and versatility, making it suitable for a wide range of industries. The GX-640 can handle various materials, delivers accurate cuts, and is designed for easy use. It a reliable and efficient tool for businesses that require precise cutting capabilities.'
  },

  {
    image: infrastructure06,
    name: 'Laser Cutting-Machines',
    // description: 'Laser cutting machines are high-precision tools used to cut materials using a focused laser beam. They are widely used in industries like manufacturing, engineering, and art to create intricate and precise shapes and patterns. Laser cutting machines can work with a variety of materials, including metals, plastics, wood, and fabrics.'
  },
  {
    image: infrastructure07,
    name: 'CNC Router Cutting Machine',
    // description: 'CNC router cutting machines are versatile tools used to cut and shape materials with precision and efficiency. They are controlled by computer software that directs a rotating cutter to follow programmed paths. CNC routers can work with a variety of materials, including wood, plastic, metal, and stone. They are commonly used in industries like woodworking, furniture manufacturing, sign making, and prototyping.'
  },
  {
    image: infrastructure08,
    name: 'Lithrone 428 Colour Offset Printing Machine',
    // description: 'The Komori Lithrone 428 is a high-performance offset printing machine designed for commercial printing applications. It offers fast print speeds, high-quality prints, and advanced automation features. The Lithrone 428 is suitable for a wide range of printing jobs, including brochures, catalogs, and packaging. It is a reliable and efficient tool for businesses that require high-quality offset printing.'
  },
  // {
  //   image: infrastructure09,
  //   name: 'Lithrone 428 Colour Offset Printing Machine',
  //   // description: 'The Komori Lithrone 428 is a high-performance offset printing machine designed for commercial printing applications. It offers fast print speeds, high-quality prints, and advanced automation features. The Lithrone 428 is suitable for a wide range of printing jobs, including brochures, catalogs, and packaging. It is a reliable and efficient tool for businesses that require high-quality offset printing.'
  // }
];
