import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useDeviceType from '../utils/useDeviceType';
import servicesDesktopGifPath from '../assets/videos/updated_videos/services_desktop.mp4';
import servicesMobileGifPath from '../assets/videos/updated_videos/services_mobile.mp4';
import Layout from './Layout';
import axios from 'axios';
import { API_URL } from '../constant';

const Services = () => {
  const [services, setServices] = useState([]);
  const { categoryName } = useParams();
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const serviceVideoRef = useRef(null);
  const [isVideoPlayed, setIsVideoPlayed] = useState(true);
  const clickableAreaHeight = deviceType === 'mobile' ? '9%' : '14%';
  const clickableAreaWidth = deviceType === 'mobile' ? '16%' : '9%';

  const fallbackClickableAreas = [
    { name: 'Retail Branding', top: deviceType === 'mobile' ? '53%' : '50%', left: deviceType === 'mobile' ? '28%' : '37%' },
    { name: '3D Acrylic Letter', top: deviceType === 'mobile' ? '53%' : '51%', left: deviceType === 'mobile' ? '11%' : '27%' },
    { name: 'Franchise Stores', top: deviceType === 'mobile' ? '46%' : '39%', right: deviceType === 'mobile' ? '83%' : '69%' },
    { name: 'Event and Exhibition', top: deviceType === 'mobile' ? '38%' : '25%', right: deviceType === 'mobile' ? '78%' : '66%' },    
    { name: 'Commercial Vehicle Branding', top: deviceType === 'mobile' ? '29%' : '10%', right: deviceType === 'mobile' ? '70%' : '62%' },
    { name: 'Commercial Plant Branding', top: deviceType === 'mobile' ? '28%' : '9%', left: deviceType === 'mobile' ? '31%' : '38%' },
    { name: 'Product Display', top: deviceType === 'mobile' ? '28%' : '9%', left: deviceType === 'mobile' ? '48.5%' : '47.5%' },
    { name: 'Offset Printing', top: deviceType === 'mobile' ? '30.5%' : '12%', left: deviceType === 'mobile' ? '71%' : '59%' },
    { name: 'Hoarding and Large scale Commercial Branding', top: deviceType === 'mobile' ? '38%' : '25.5%', left: deviceType === 'mobile' ? '81.5%' : '65%' },
    { name: 'Railway Advertising', top: deviceType === 'mobile' ? '47%' : '41%', left: deviceType === 'mobile' ? '81%' : '65%' },
    { name: 'Mall Branding', top: deviceType === 'mobile' ? '53%' : '50%', left: deviceType === 'mobile' ? '69%' : '58%' },
    { name: 'All Festival Branding', top: deviceType === 'mobile' ? '52%' : '48%', left: deviceType === 'mobile' ? '53%' : '50%' },  
  ];

  const fetchAllServices = useCallback(async () => {
    try {
      const response = await axios.post(`${API_URL}/services`, {
        params: { categoryName: categoryName },
      });
      setServices(response.data ?? []);
    } catch (error) {
      console.error('Failed to fetch services:', error);
    }
  }, [categoryName]);

  useEffect(() => {
    fetchAllServices();
  }, [fetchAllServices]);

  useEffect(() => {
    const video = serviceVideoRef.current;
    const playVideo = async () => {
      if (video) {
        try {
          await video.play();
          setIsVideoPlayed(true);
        } catch (error) {
          console.error('Error attempting to play the video:', error);
        }
      }
    };

    const timeoutId = setTimeout(playVideo, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  // const handleBranchClick = (categoryName) => {
  //   if (isVideoPlayed) {
  //     // navigate(`/services/${categoryName}`);
  //     navigate(`/service-details?category=${categoryName}`);
  //   }
  // };

  const handleBranchClick = (categoryName) => {
    if (isVideoPlayed) {
      const encodedCategoryName = encodeURIComponent(categoryName); 
      navigate(`/service-details?category=${encodedCategoryName}`);
    }
  };

  return (
    <Layout>
      <div
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          height: deviceType === 'mobile' ? '80vh' : '80vh',
          margin: 0,
          padding: 0,
        }}
      >
        <video
          ref={serviceVideoRef}
          src={deviceType === 'mobile' ? servicesMobileGifPath : servicesDesktopGifPath}
          type='video/mp4'
          autoPlay
          muted
          controls={false}
          style={{
            width: deviceType === 'mobile' ? '100%' : '75.5%',
            objectFit: 'cover',
          }}
        />
  
        {services.length > 0 ?
          services?.map(({ categoryName, top, left, right }, index) => (
            <div
              key={index}
              onClick={() => handleBranchClick(categoryName)}
              style={{
                position: 'absolute',
                top: top || fallbackClickableAreas[index]?.top,
                left: left || fallbackClickableAreas[index]?.left,
                right: right || fallbackClickableAreas[index]?.right,
                width: deviceType === 'mobile' ? '11%' : '14%',
                height: deviceType === 'mobile' ? '6%' : '15%',
                cursor: 'pointer',
                pointerEvents: 'auto',
                zIndex: 10,
              }}
            ></div>
          ))
          : fallbackClickableAreas?.map(({ name, top, left, right }, index) => (
            <div
              key={index}
              onClick={() => handleBranchClick(name)}
              style={{
                position: 'absolute',
                top,
                left,
                right,
                width: clickableAreaWidth,
                height: clickableAreaHeight,
                borderRadius: '50%',
                cursor: 'pointer',
                pointerEvents: 'auto',
                zIndex: 10
              }}
            ></div>
          ))
        }
      </div>
    </Layout>
  );
};

export default Services;
