import React from 'react';
import Card from '../components/Card';
import Layout from './Layout';
import useDeviceType from '../utils/useDeviceType';
import { infrastructureData as Infrastructure } from '../json/Infrastructure';
import Breadcrumb from '../components/Breadcrumb';
import video_three from '../assets/videos/WEBSITE VIDEO_03.mp4';
import video_one from '../assets/videos/WEBSITE VIDEO_01.mp4';
import video_two from '../assets/videos/WEBSITE VIDEO_02.mp4';

const OurInfraStructure = () => {
  const deviceType = useDeviceType();

  const containerStyle = {
    height: 'auto',
    width: '100%',
    color: 'black',
    padding: deviceType === 'mobile' ? '10px' : '30px',
  };

  return (
    <Layout>
      <Breadcrumb path={['Home', 'Our Infrastructure']} />
      <div style={containerStyle}>
        <div
          style={{
            padding: '20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: deviceType === 'mobile' ? '100%' : '55%',
            margin: 'auto',
            overflow: 'hidden'
          }}
        >
          <video
            src={video_three}
            type="video/mp4"
            autoPlay
            muted
            loop
            controls={false}
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '12px',
            }}
          />
        </div>
        <div style={{
              display: 'flex',
              flexDirection: deviceType === 'mobile' ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
              padding: '20px',
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              maxWidth: '100%',
              marginTop: '50px',
              overflow: 'hidden',
              flexWrap: 'wrap',
            }}>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: deviceType === 'mobile' ? '100%' : '48%',
                marginBottom: '20px',
              }}>
                <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: '600', marginBottom: '20px' }}>Laser Cutting</h2>
                <video
                  src={video_one}
                  type="video/mp4"
                  muted
                  controls
                  style={{
                    border: '1px solid',
                    borderColor: '#fce4de',
                    borderRadius: '12px',
                    height: 'auto',
                    maxHeight: deviceType === 'mobile' ? '' : '550px',
                    aspectRatio: deviceType === 'mobile' ? '' : '16 / 9',
                    boxSizing: 'border-box',
                    width: '100%',
                  }}
                />
              </div>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: deviceType === 'mobile' ? '100%' : '48%',
                marginBottom: '20px',
              }}>
                <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: '600', marginBottom: '20px' }}>CNC Router Cutting</h2>
                <video
                  src={video_two}
                  type="video/mp4"
                  muted
                  controls
                  style={{
                    border: '1px solid',
                    borderColor: '#fce4de',
                    borderRadius: '12px',
                    height: 'auto',
                    maxHeight: deviceType === 'mobile' ? '' : '550px',
                    aspectRatio: deviceType === 'mobile' ? '' : '16 / 9',
                    boxSizing: 'border-box',
                    width: '100%',
                  }}
                />
              </div>
            </div>
        <section 
          style={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: '40px',
            marginTop: '50px',
          }}
        >
          
          {Infrastructure?.map((infra) => (
            <Card 
              key={infra?.id || infra?.name}
              image={infra?.image} 
              name={infra?.name} 
              // description={infra?.description} 
            />
          ))}
        </section>
      </div>
    </Layout>
  );
};

export default OurInfraStructure;
