import React, { useState } from 'react';
import { headerMenus } from '../json/headerMenus';
import logo from '../assets/images/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import useDeviceType from '../utils/useDeviceType';
import '../Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const deviceType = useDeviceType();

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (deviceType === 'mobile') {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav
      style={{
        backgroundColor: '#fcf8f7',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        padding: '15px 5%',
        marginBottom: '10px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={() => navigate('/')}
      >
        <img src={logo} style={{ width: 'auto', height: 'auto' }} alt='Logo' />
      </div>

      <div>
        {deviceType === 'mobile' ? (
          <>
            <div
              className='hamburger'
              onClick={toggleMenu}
              style={{
                width: '20%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '30px',
                cursor: 'pointer',
              }}
              aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            >
              {isMenuOpen ? '✖' : '☰'}
            </div>
            {isMenuOpen && (
              <ul className='mobile-menu'>
                {headerMenus?.map((menu, index) => (
                  <li key={index}>
                    <p
                      onClick={() => handleNavigation(menu?.path)}
                      style={{
                        color: location.pathname === menu.path ? 'red' : 'black',
                        fontWeight: '800'
                      }}
                    >
                      {menu?.name}
                      <hr />
                    </p>
                  </li>
                ))}
                <li>
                  <button className='portal-button'>Portal Login</button>
                </li>
              </ul>
            )}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <ul
              style={{
                listStyleType: 'none',
                display: 'flex',
                justifyContent: 'end',
                gap: '35px',
                paddingBottom: '25px',
                marginRight: '20px',
              }}
            >
              {headerMenus?.map((menu, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: 16,
                    cursor: 'pointer',
                    marginTop: '24px',
                    width: 'auto',
                  }}
                >
                  <p
                    onClick={() => navigate(menu?.path)}
                    style={{
                      color: location.pathname === menu.path ? 'red' : '#272A2C',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      margin: 0,
                      fontWeight: '800',
                    }}
                    className='header-link'
                  >
                    {menu?.name}
                  </p>
                </li>
              ))}
            </ul>

            <button className='portal-button'> 
              <a href='https://eloraart.com/eloraartportal/login.php' 
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                PORTAL LOGIN
              </a> 
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
