import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import Input from '../components/Input';
import axios from 'axios';
import useDeviceType from '../utils/useDeviceType';
import TextArea from '../components/Textarea';
import Breadcrumb from '../components/Breadcrumb';
import { FaBuilding, FaLandmark } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import { LuPhoneCall } from 'react-icons/lu';
import TickerTape from '../components/TickerTape';
import { API_URL } from '../constant';

const contactInfo = [
  { icon: TfiEmail, email: 'raju@eloraart.com' },
  { icon: TfiEmail, email: 'rajueloraart@gmail.com' },
  { icon: TfiEmail, email: 'rahul@eloraart.com' },
  { icon: TfiEmail, email: 'rahuleloraart@gmail.com' },
  { icon: LuPhoneCall, phone: '+91 99201 53533' },
  { icon: LuPhoneCall, phone: '+91 72083 41924' },
  { icon: LuPhoneCall, phone: '+91 89888 88081' },
];
    
const offices = [
  {
    label: 'Mumbai Registered Office',
    address: '306 Shivam Chamber, Near Ram Mandir Signal, S.V. Road, Goregoan West, Mumbai 400104, Maharashtra, India.',
    isHeadquarters: true,
  },
  {
    label: 'Nagpur Branch',
    address: 'Sharda Bhavan, Dhondha Chowk, Behind Seva Sadan, C.A. Road, Nagpur 440012.',
    isHeadquarters: false,
  },
  {
    label: 'Varansi Branch',
    address: 'D/305, Chandra Nagar, Bhagwanpur, Near Gardaniya School, Lanka Varanasi, Uttar Pradesh 221005.',
    isHeadquarters: false,
  },
  {
    label: 'Pune Branch',
    address: 'Sai Apartment, 306/307, Darshan Park Society Rd, Shambhu Vihar Society Aundh, Pune - 411007, Maharashtra, India.',
    isHeadquarters: false,
  },
  {
    label: 'Ahmedabad Branch',
    address: 'Barcelona Industrial Park, SP Ring Road, Odhav, Opp., Odhav Police Station, Ahmedabad - 382415, Gujarat, India.',
    isHeadquarters: false,
  },
];

const ContactUs = () => {
  const deviceType = useDeviceType();
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    mobile: '',
    subject: '',
    message: '',
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);

  useEffect(() => {
    if (showResponseMessage) {
      const timeout = setTimeout(() => {
        setShowResponseMessage(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [showResponseMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!API_URL) {
      setResponseMessage('API URL is not configured.');
      setShowResponseMessage(true);
      return;
    }
    try {
      setLoading(true);
      await axios.post(`${API_URL}/store-enquiry`, formData);
      setResponseMessage('Enquiry submitted successfully!');
      setShowResponseMessage(true);
      setFormData({ full_name: '', email: '', mobile: '', subject: '', message: '' });
      setValidationErrors({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errors = error.response?.data?.errors || {};
      setValidationErrors(errors);
      setResponseMessage(errors ? '' : 'Failed to submit enquiry. Please try again.');
      setShowResponseMessage(true);
    }
  };

  const displayValidationError = (field) => {
    return validationErrors[field] && (
      <p style={{ color: 'red', fontSize: '0.875rem', marginBottom: '5px' }}>
        {validationErrors[field]}
      </p>
    );
  };

  const renderInfo = (title, items, isOffice = false) => (
    <div style={styles.infoContainer}>
      <h2 style={styles.infoTitle}>{title}</h2>
      <hr style={styles.infoDivider} />

      <div style={styles.infoText}>
        {items?.map((item, index) => (
          <div key={index} style={{ ...styles.infoItem, display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>
              {isOffice ? (
                item.isHeadquarters ? <FaLandmark style={styles.iconLarge} /> : <FaBuilding style={styles.iconSmall} />
              ) : (
                item.icon && <item.icon style={styles.iconSmall} />
              )}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flex: 1 }}>
              <strong>
                {item.label || item.email || (item.phone && `Call Us: ${item.phone}`)}
              </strong>
              {item.address && <span style={{ marginTop: '2px' }}>{item.address}</span>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Layout>
      <Breadcrumb path={['Home', 'Contact Us']} />

      <TickerTape tickerText={" • Let's discuss how we can elevate your brand visibility and impact. Contact us today for a consultation! •"} />

      <div style={styles.mainContainer(deviceType)}>
        <div style={{ ...styles.leftColumn, width: deviceType === 'mobile' ? '100%' : '60%' }}>
          {renderInfo('Contact Details', contactInfo)}
          {renderInfo('Office Locations', offices, true)}
        </div>

        <section style={{...styles.section(deviceType), width: deviceType === 'mobile' ? '100%' : '35%'}}>
          <div style={styles.formContainer}>
            <form onSubmit={handleSubmit}>
              {displayValidationError('full_name')}
              <Input
                label='Full Name'
                placeholder='Enter your full name'
                type='text'
                name='full_name'
                value={formData.full_name}
                onChange={handleChange}
                required
                style={styles.input}
              />

              {displayValidationError('email')}
              <Input
                label='Email'
                placeholder='Enter your email'
                type='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                required
                style={styles.input}
              />
              
              {displayValidationError('mobile')}
              <Input
                label='Mobile Number'
                placeholder='Enter your mobile number'
                type='number'
                name='mobile'
                value={formData.mobile}
                onChange={handleChange}
                required
                style={styles.input}
              />
              
              {displayValidationError('subject')}
              <Input
                label='Subject'
                placeholder='Enter your subject'
                type='text'
                name='subject'
                value={formData.subject}
                onChange={handleChange}
                required
                style={styles.input}
              />
              
              {displayValidationError('message')}
              <TextArea
                label='Message'
                placeholder='Enter your message'
                name='message'
                value={formData.message}
                onChange={handleChange}
                required
                style={styles.textArea}
              />
              
              <div>
                {showResponseMessage && responseMessage ? (
                  <div
                    style={{
                      marginBottom: '10px',
                      textAlign: 'center',
                      backgroundColor: responseMessage.includes('Failed') ? 'red' : '#1fcf25',
                      padding: '10px',
                      borderRadius: '8px',
                    }}
                  >
                    <p style={{ color: 'white', fontWeight: '600', fontSize: '18px' }}>
                      {responseMessage}
                    </p>
                  </div>
                ) : (
                  <button
                    type='submit'
                    style={styles.submitButton}
                    onMouseOver={(e) => (e.target.style.backgroundColor = '#0056b3')}
                    onMouseOut={(e) => (e.target.style.backgroundColor = '#007BFF')}
                  >
                    {loading ? 'Loading...' : 'Submit'}
                  </button>
                )}
              </div>
            </form>
          </div>
        </section>
      </div>

      <div style={styles.mapContainer(deviceType)}>
        <iframe
          title='Location Map'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.950119756272!2d72.84280547497937!3d19.153660582066898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b71c79164b23%3A0xae40753a8612ec9d!2sElora%20Art%20-%20The%20Brand%20Promotion!5e0!3m2!1sen!2sin!4v1726665664186!5m2!1sen!2sin'
          width='100%'
          height='450px'
          style={{ border: '0' }}
          allowFullScreen
          loading='lazy'
        ></iframe>
      </div>
    </Layout>
  );
};

// Styles
const styles = {
  mainContainer: (deviceType) => ({
    display: 'flex',
    flexDirection: deviceType === 'mobile' ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: deviceType === 'mobile' ? '20px' : '40px'
  }),
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  infoContainer: {
    backgroundColor: '#c75d4a',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px'
  },
  infoTitle: {
    color: 'white',
    fontWeight: 600,
  },
  infoDivider: {
    borderTop: '1.5px solid white',
    margin: '2px 0',
    width: '80%',
  },
  infoText: {
    marginTop: '20px',
    color: 'white',
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  iconLarge: { fontSize: '25px', marginRight: '8px', color: 'white' },
  iconSmall: { fontSize: '20px', marginRight: '8px', color: 'white' },
  section: (deviceType) => ({
    display: 'flex',
    flexDirection: deviceType === 'mobile' ? 'column' : 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  }),
  imageContainer: { flex: '1', padding: '20px' },
  image: (deviceType) => ({
    width: '100%',
    height: deviceType === 'mobile' ? '300px' : '350px',
    borderRadius: '10px',
  }),
  input: { flex: '1', marginBottom: '10px' },
  mapContainer: (deviceType) => ({
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
    padding: deviceType === 'mobile' ? '0 30px' : '0 60px',
  }),
  errorText: { color: 'red', fontSize: '0.9em', marginTop: '5px' },
  formContainer: {
    width: '100%',
    margin: 'auto',
    padding: '30px',
    borderRadius: '10px',
    backgroundColor: '#f7ebe9',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  row: (deviceType) => ({
    display: 'flex',
    flexDirection: deviceType === 'mobile' ? 'column' : 'row',
    gap: '15px',
    marginBottom: '20px',
  }),
  textArea: {
    width: '100%',
    padding: '12px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    resize: 'vertical',
    backgroundColor: 'white'
  },
  submitButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    width: '100%',
    marginTop: '25px',
    fontWeight: '700',
    fontSize: '16px'
  },
};

export default ContactUs;
