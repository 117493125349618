import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import useDeviceType from '../utils/useDeviceType';

const Layout = ({children}) => {
  const deviceType = useDeviceType();
  const isMobile = deviceType === 'mobile';

  return (
    <div style={{width: '100%', overflowX: 'hidden'}}>
      <div style={{zIndex: 3}}><Navbar /></div>
      <div style={{padding: isMobile ? '0px' : '15px 5%', zIndex: 1}}>{children}</div>
      <div style={{zIndex: 3}}><Footer /></div>
    </div>
  )
}

export default Layout
