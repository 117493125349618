import React from 'react';

const Network = ({ isOpen, onClose, title, name }) => {
  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
        <span style={modalStyles.closeIcon} onClick={onClose}>×</span>
        <h2 style={modalStyles.title}>{title}</h2>
        <p style={modalStyles.content}>{name}</p>
      </div>
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    position: 'relative',
    backgroundColor: '#fcf8f7',
    padding: '20px 20px 30px',
    borderRadius: '10px',
    textAlign: 'center',
    width: '400px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  closeIcon: {
    position: 'absolute',
    top: '0px',
    right: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '40px',
    color: '#FF6347',
    cursor: 'pointer',
  },
  title: {
    fontSize: '24px',
    marginBottom: '10px',
    marginTop: '10px',
  },
  content: {
    fontSize: '16px',
    color: '#555',
  },
};

export default Network;
