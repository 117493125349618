export const headerMenus = [
  {
    name: "Home",
    path: "/",
    id: 1,
  },
  {
    name: "Services",
    path: "/services",
    id: 2,
  },
  {
    name: "About Us",
    path: "/about-us",
    id: 3,
  },
  {
    name: "Infrastructure",
    path: "/our-infrastructure",
    id: 4,
  },
  {
    name: "Our Team",
    path: "/our-team",
    id: 5,
  },
  {
    name: "Our Network",
    path: "/our-network",
    id: 6,
  },
  {
    name: "Contact Us",
    path: "/contact-us",
    id: 7,
  },
];
