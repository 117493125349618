import React from 'react';

const Network = ({ isOpen, onClose, designation, name, imageSrc = null }) => {
  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
        <button onClick={onClose} style={modalStyles.closeButton} aria-label="Close modal">
          &times;
        </button>
        
        <div style={modalStyles.imageContainer}>
          {imageSrc ? (
            <img src={imageSrc} alt='Network' style={modalStyles.image} />
          ) : (
            <p style={modalStyles.noImageText}>No image available</p>
          )}
        </div>
        
        <h2 style={modalStyles.title}>{name}</h2>
        <p style={modalStyles.designation}>{designation}</p>
      </div>
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#fcf8f7',
    padding: '30px',
    borderRadius: '10px',
    textAlign: 'start',
    width: '400px',
    height: 'auto',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '0px',
    right: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '40px',
    color: '#FF6347',
    cursor: 'pointer',
  },
  imageContainer: {
    marginTop: '20px',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  noImageText: {
    textAlign: 'center',
    color: '#666',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.5rem',
    margin: '10px 0',
  },
  designation: {
    textAlign: 'center',
    fontSize: '1rem',
    color: '#555',
  },
};

export default Network;
